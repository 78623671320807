<template>
  <div class="auth-page">
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card class="auth-card">
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link
              to="/"
              class="d-flex align-center"
            >
              <h2 class="text-2xl font-weight-semibold">
                <span class="primary--text">Strong Expert</span>
              </h2>
            </router-link>
          </v-card-title>

          <v-card-text>
            <validation-observer
              ref="formRecoveryPassword"
              autocomplete="off"
              tag="form"
              @keyup.enter="update()"
            >
              <validation-provider
                v-slot="{ errors }"
                name="E-mail"
                rules="required|email"
                vid="email"
              >
                <v-text-field
                  v-model="email"
                  :error-messages="errors"
                  outlined
                  dense
                  label="E-mail"
                />
              </validation-provider>

              <v-alert
                :icon="icons.mdiInformationOutline"
                text
                outlined
                color="info"
              >
                Após clicar no botão <b>Enviar</b>, você receberá um e-mail contendo um link para redefinir sua senha.
              </v-alert>

              <v-btn
                block
                color="primary"
                @click="update()"
              >
                Enviar
              </v-btn>
            </validation-observer>
          </v-card-text>

          <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
            <span class="me-2">
              Já tem uma conta?
            </span>
            <router-link :to="{ name:'signin' }">
              Faça login
            </router-link>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <div
      v-for="index in 6"
      :key="index"
      class="cube"
    />
  </div>
</template>

<script>
import { mdiInformationOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { mapActions } from 'vuex'

export default {
  setup() {
    const email = ref('')

    return {
      email,
      icons: {
        mdiInformationOutline,
      },
    }
  },

  methods: {
    ...mapActions('auth', [
      'recoveryPassword',
    ]),

    async update() {
      if (await this.$refs.formRecoveryPassword.validate()) {
        const status = await this.recoveryPassword({
          email: this.email,
        })

        if (status === 201) {
          this.$router.push('/signin')
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
